<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="row">
                                            <div class="col-6">
                                                <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                            <div class="col-6 text-right">
                                                <v-btn class="mt-3 mr-3" color="primary" elevation="2" small rounded @click="showDialogCreateMpf()">Create MPF<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateMpfList()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                            </div>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <div class="d-flex justify-content-end">
                                        <!-- <v-btn v-if="isApprove1 == true && item.approve1_by == ''" class="mr-3" color="success" elevation="2" small rounded @click="approve1MPF(item)">Approve</v-btn>
                                        <v-btn v-if="isApprove1 == true && item.approve1_by == ''" class="mr-3" color="red" elevation="2" small rounded @click="reject1MPF(item)" dark>Reject</v-btn> -->
                                        <v-btn class="" color="secondary" elevation="2" small rounded @click="showItemDetails(item)">Details</v-btn>
                                    </div>
                                </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogCreateMpf" max-width="1024px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Create MPF</h5>
                        <div class="row">
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfEntityModel" :items="mpfEntityLists" item-value="entity_id" item-text="description" label="Entity" @change="(event) => entityOnChange(event)" clearable dense prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfCategoryModel" :items="mpfCategoryLists" item-value="cat_id" item-text="cat_desc" label="Category" @change="(event) => categoryOnChange(event)" no-data-text="Please Select Entity !" clearable dense prepend-icon="mdi-checkbox-multiple-blank" return-object></v-select>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-text-field v-model="mpfOtherCategoryModel" v-show="showOthercategory" label="Other Category" dense prepend-icon="mdi-checkbox-multiple-blank"></v-text-field>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfReceiverModel" :items="mpfReceiverLists" item-value="group_id" item-text="deskripsi" label="Receiver" no-data-text="Please Select Entity !" clearable dense prepend-icon="mdi-account-multiple"></v-select>
                            </div>
                            <div class="col-lg-6 col-12">
                                <v-text-field v-model="mpfOrderNoModel" label="Order No." dense prepend-icon="mdi-content-paste"></v-text-field>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-textarea outlined v-model="mpfRemarkModel" counter label="MPF Remark"></v-textarea>
                            </div>
                            <div class="col-lg-12 col-12">
                                <input type="file" name="image" id="image" @change="fileChange" class="" accept="image/*" />
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mt-3 mr-3" color="red" elevation="2" small rounded @click="dialogCreateMpf = false" dark>Cancel</v-btn>
                                <v-btn class="mt-3" color="success" elevation="2" small rounded @click="submitMPF()">Submit</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogDetailMpf" max-width="720px" >
            
                <div class="card elevation-5">
                    <div class="card-header bg-transparent border-bottom-2 text-right">
                        <v-btn class="" color="red" elevation="2" small @click="dialogDetailMpf = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Detail MPF</h5>
                        <h5 class="card-title">Attachment : <v-chip color="blue" v-if="imageDetail" @click="showImage()" small dense dark> Click for view attachment </v-chip> </h5>
                        <h5 class="card-title">Remark : </h5>
                        <div class="row">
                            <div class="col-lg-12 col-12 d-flex">
                                <!-- <v-textarea outlined v-model="mpfDetailRemarkModel" disabled counter label="MPF Remark"></v-textarea> -->
                                <ul>
                                    <li v-for="item in mpfDetailsList" :key="item.seq_no">
                                        <span>{{ item.created_by.toUpperCase() }} : {{ item.message_body }} ({{ item.formattedDate }})</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-12 col-12 d-flex">
                                <v-text-field class="pt-5" v-model="addCommentModel" label="Add comment here..." dense></v-text-field>
                                <v-btn class="" color="success" elevation="2" small @click="insertingComment()" dense>Add Comment</v-btn>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-autocomplete v-model="ccModel" :items="ccList" item-value="group_id" item-text="deskripsi" outlined dense chips small-chips label="CC Recipients" multiple ></v-autocomplete>
                            </div>
                            <div v-if="mpfStat == 'S'" class="col-lg-12 col-12 d-flex justify-content-end">
                                <v-btn v-if="isApprove1 == true" class="mt-3 mr-3" color="success" elevation="2" small rounded @click="approve2MPF()">Approve</v-btn>
                                <v-btn v-if="isApprove1 == true" class="mt-3" color="red" elevation="2" small rounded @click="reject2MPF()" dark>Reject</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogShowImage" max-width="768px" >
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-img :src = imgUrl ></v-img>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'MPF',
                disabled: false,
                href: '#',
                },
                {
                text: 'MPF List',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'FROM', value: 'fromStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'TO', value: 'toStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'SUBJECT', value: 'cat_desc', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                // { text: '', value: 'details', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemLists: [],
            searchItem: '',
            dialogCreateMpf: false,
            senderEntity:'',
            mpfEntityModel: '',
            mpfEntityLists: [],
            mpfReceiverModel: '',
            mpfReceiverLists: [],
            mpfCategoryModel: '',
            mpfCategoryLists: [],
            mpfOtherCategoryModel: '',
            showOthercategory: false,
            mpfCCModel: '',
            mpfCCLists: [],
            mpfRemarkModel: '',
            loadingDatatable: false,
            mpfOrderNoModel: '',
            imageModel: '',
            userMPFGroupModel: '',
            dialogDetailMpf: false,
            mpfDetailRemarkModel: '',
            mpfDetailsList:[],
            addCommentModel: '',
            mpfDetailTrId: '',
            isApprove1: false,
            mpfStat: '',
            imageDetail: '',
            dialogShowImage: false,
            imgUrl: '',
            ccModel: '',
            ccList: []
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){

            this.populateMpfList()
            

        },

        async populateMpfList(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MPFList`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false
                
                if(this.userMPFGroup == 'false'){
                    Swal.fire({
                        title: 'Access Denied',
                        text: "You won't be able to create MPF, please call IT Administrator!",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        this.$router.push('/admin/dashboard');
                    })
                } else {

                    this.userMPFGroupModel = res.data.mpf_group
                    this.mpfEntityLists = res.data.entity
                    this.senderEntity = res.data.senderEntity
                    this.itemLists = res.data.mpfList

                    if(res.data.isApprove == '1'){
                        this.isApprove1 = true
                    }

                }
                
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async submitMPF(){

            if(this.mpfEntityModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Entity !'
                })

                return false

            }

            if(this.mpfCategoryModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Category !'
                })

                return false

            }

            if(this.mpfReceiverModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Receiver !'
                })

                return false

            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/submitMPF`, { 

                    senderEntity: this.senderEntity ? this.senderEntity : '',
                    toEntity: this.mpfEntityModel ? this.mpfEntityModel : '',
                    category: this.mpfCategoryModel.cat_id ? this.mpfCategoryModel.cat_id : '',
                    otherCategory: this.mpfOtherCategoryModel ? this.mpfOtherCategoryModel : '',
                    receiver: this.mpfReceiverModel ? this.mpfReceiverModel : '',
                    order_id: this.mpfOrderNoModel ? this.mpfOrderNoModel : '',
                    remark: this.mpfRemarkModel ? this.mpfRemarkModel : '',
                    image: this.imageModel ? this.imageModel : '',
                    mpfGroup: this.userMPFGroupModel ? this.userMPFGroupModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                console.log(res.data)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showItemDetails(id){

            this.$store.dispatch('setOverlay', true)

            this.mpfDetailTrId = id.tr_id
            this.mpfStat = id.stat

            console.log(id.stat)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/getDetails`, {

                id: id.tr_id ? id.tr_id : '',

            },
            { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                this.dialogDetailMpf = true
                this.mpfDetailsList = res.data.result
                this.imageDetail = res.data.image
                this.ccList = res.data.group_cc
                // console.log(this.imageDetail)
                
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
           
        },

        showDialogCreateMpf(){

            this.dialogCreateMpf = true
            this.mpfEntityModel = ''
            this.mpfCategoryModel = ''
            this.mpfOtherCategoryModel = ''
            this.mpfReceiverModel = ''
            this.mpfOrderNoModel = ''
            this.mpfRemarkModel = ''
            this.imageModel = ''

        },

        async entityOnChange(id){

            this.mpfCategoryLists = []
            this.mpfCategoryModel = ''
            this.mpfOtherCategoryModel = ''
            this.mpfReceiverLists = []
            this.mpfReceiverModel = ''
            this.mpfOrderNoModel = ''
            this.mpfRemarkModel = ''
            this.imageModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MPFList/getCategoryByEntity?entity=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.mpfCategoryLists = res.data.category
                this.mpfReceiverLists = res.data.receiver

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async categoryOnChange(id){

            if(id.cat_desc == 'OTHER'){
                this.showOthercategory = true
            } else {
                this.showOthercategory = false
            }

        },

        fileChange(e) {
            let file = e.target.files[0]
            this.imageModel = ''

            if(file){

                if (!file.type.includes('image/')) {
                    swal("Error", "Please select an image file!", "error");
                    return
                }
                // if (file.size > 10000000) {
                //     swal("Failed", "File image maximum 10 MB!", "error");
                //     return
                // }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        this.imageModel = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    swal("Error", "Sorry, FileReader API not supported", "error");
                }

            }

        },

        async insertingComment(){

            if(this.addCommentModel){

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/addDetailMPF`, {

                        id: this.mpfDetailTrId ? this.mpfDetailTrId : '',
                        remark: this.addCommentModel ? this.addCommentModel : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.mpfDetailsList = []
                        this.mpfDetailsList = res.data.result
                        this.addCommentModel = ''
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

            }

        },

        approve1MPF(id){

            Swal.fire({
                text: 'Do you want Approve this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'Cancel'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/approve1MPF`, {

                        id: id.tr_id ? id.tr_id : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        if(res.data.result == 'OK'){

                            this.dialogDetailMpf = false
                            this.populateMpfList()

                        }
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        reject1MPF(id){

            Swal.fire({
                text: 'Do you want Reject this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'Cancel'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/reject1MPF`, {

                        id: id.tr_id ? id.tr_id : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        if(res.data.result == 'OK'){

                            this.dialogDetailMpf = false
                            this.populateMpfList()

                        }
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        approve2MPF(){

            Swal.fire({
                text: 'Do you want Approve this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'Cancel'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/MPFList/approve2MPF`, {

                        id: this.mpfDetailTrId ? this.mpfDetailTrId : '',
                        cc: this.ccModel ? this.ccModel : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        if(res.data.result == 'OK'){

                            this.dialogDetailMpf = false
                            this.populateMpfList()

                        }
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        reject2MPF(){



        },

        showImage(){

            this.imgUrl = process.env.VUE_APP_URL + this.imageDetail
            this.dialogShowImage = true

        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>
.v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }


</style>